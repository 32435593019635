/* RegistrarUsuario.css */

/* Contenedor principal */
.card-title-desc {
    font-size: 1.1rem;
    color: #6c757d;
  }
  
  /* Botón de salir */
  .btn-danger {
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  /* Botón de registrar */
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-primary[disabled] {
    background-color: #6c757d;
    border-color: #6c757d;
  }
  
  /* Espaciado entre filas del formulario */
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  /* Estilos personalizados para react-select */
  .select2-selection__control {
    z-index: 1000;
  }
  
  .select2-selection__menu {
    z-index: 1050;
  }
  
  /* Estilos adicionales opcionales para mejor apariencia */
  .form-control {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  label {
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #343a40;
  }